<script lang="ts">
  import { getContext } from 'svelte'
  import MyButton from '../../../../../atoms/my-button.svelte'
  import { FactCheckingService, FactDateRange } from '../fact-checking'
  import Infinity from '../../../../../../assets/icons/infinity.svelte'
  import { RIGHT_PANEL_CONTEXT } from '../../../right-panel'

  export let data: any
  export let disabled: boolean
  export let factChecking: FactCheckingService

  const { actualDateRange } = factChecking

  $: active = $actualDateRange === data.enumValue

  const { columns } = getContext(RIGHT_PANEL_CONTEXT) as any
</script>

<div class="range-button" class:not-active={!active}>
  <MyButton kind="primary" onClick={() => factChecking.setDateRange(data.enumValue)} {disabled}>
    <div class={`flex items-center w-full ${$columns === 1 ? 'flex-col' : 'gap-1'}`}>
      {#if data.enumValue === FactDateRange.ANY}
        <Infinity color={`${disabled ? 'var(--cds-disabled-03)' : 'white'}`} />
      {:else}
        <div>
          <p class="text-s">{data.topLabel}</p>
        </div>
      {/if}
      {#if data.enumValue !== FactDateRange.CUSTOM}
        <div>
          <p class="text-s">{data.bottomLabel}</p>
        </div>
      {/if}
    </div>
  </MyButton>
</div>

<style lang="postcss">
  .range-button {
    width: 20%;
    max-width: 20%;
  }

  .range-button.not-active :global(.my-button) {
    background-color: rgb(var(--accent-rgb));
  }

  .range-button.not-active:hover :global(.my-button) {
    background-color: rgba(var(--accent-rgb), 0.6);
  }

  .range-button :global(.my-button) {
    @apply p-[10px];
    min-width: 100%;
    text-align: center;
    height: 100%;
    min-height: fit-content;
  }
</style>
