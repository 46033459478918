<script lang="ts">
  import { onMount } from 'svelte'
  import type { Content } from 'common/src/types'
  import MyTextInput from '../../../../atoms/my-text-input.svelte'
  import { state } from '../../../../../store/state'
  import { SEOTab } from './seo-tab'
  import SeoSection from './seo-section.svelte'
  import { ArticleKind } from '../../../../../store/kind'

  export let content: Content

  let seoFocusWords: string = ''
  let lastId = '-'

  const seoTab = new SEOTab(state)

  const { seoSections } = seoTab
  const unsubscribe = state.lastModified.subscribe((content: Content) => {
    seoTab.processArticleContent(content)
  })

  const { currentContentIsInEdit } = state
  $: isInEdit = $currentContentIsInEdit

  $: {
    if (content && (lastId !== content.id || (lastId === content.id && !isInEdit))) {
      seoFocusWords = content.seoFocusWords
      lastId = content.id
      seoTab.processArticleContent(content)
    }
  }

  onMount(() => {
    return () => unsubscribe()
  })
</script>

<div class="seo-tab flex flex-col gap-6 max-h-full h-full w-full px-4 overflow-y-scroll">
  <div class="flex flex-col w-full">
    <MyTextInput
      labelText="SEO Focus Words"
      counter={false}
      disabled={!isInEdit || content?.type !== ArticleKind.type}
      tooltipPosition="bottom"
      bind:value={seoFocusWords}
      onInput={() => seoTab.onInputChange(seoFocusWords)}
    />
    <div class="seo flex flex-col">
      {#each $seoSections as section}
        <SeoSection title={section.title} analysisData={section.analysisData} />
      {/each}
    </div>
    <div class="min-h-[6rem]"></div>
  </div>
</div>
