<script lang="ts">
  import { getContext } from 'svelte'

  import { SEARCH_PANE_CONTEXT } from '../../search-pane'
  import { RIGHT_PANEL_CONTEXT } from '../../../right-panel'
  import { ContentKind } from '../../../../../../store/content-kind'
  import CardViewerWrapper from '../../../../../content/cards/card-viewer-wrapper.svelte'

  const { activeContentKind } = getContext(SEARCH_PANE_CONTEXT) as any
  const { open: panelOpen } = getContext(RIGHT_PANEL_CONTEXT) as any

  $: enable = panelOpen
  $: contentKind = $activeContentKind as ContentKind<any>
</script>

<CardViewerWrapper {contentKind} {enable} scale={0.75} source="search-pane" />
