import { writable } from 'svelte/store'
import { removePrefix } from 'common/src/parser'

import { State } from '../../../../../../store/state'
import { ContentKind } from '../../../../../../store/content-kind'

import { DEFAULT_SELECTED_KIND } from '../../../right-panel'

const allNameOnState: string[] = []

export const filter = (state: State) => {
  allNameOnState.length = 0
  allNameOnState.push(
    ...state.searchContents
      .map((c: ContentKind<any>) => removePrefix(c.kind.nameOnState, 'search').toLowerCase())
      .sort()
  )
  const items = writable(allNameOnState.map((kind: string, index: number) => ({ id: index, text: kind })))
  const selected = writable(kindAsId(DEFAULT_SELECTED_KIND))

  return {
    items,
    selected,
  }
}

export const kindAsString = (id: number) => allNameOnState[id]
export const kindAsId = (kind: string) => allNameOnState.indexOf(kind)
