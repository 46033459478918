export const configData = {
  wordsLength: {
    enabled: true,
    maxLength: 6,
    minLength: 1,
    score: 1,
    rule: 'SEO Focus Words length is good',
    type: 'words',
  },
  text: {
    enabled: true,
    score: 1,
    scope: 'warning',
    limit: 4,
    rule: 'SEO Focus Words in article body',
    type: 'words',
  },
  wordsInIntro: {
    enabled: true,
    score: 1,
    rule: 'SEO Focus Words in the first 30 words of article body',
    type: 'words',
  },
  lead: {
    enabled: true,
    score: 1,
    rule: 'SEO Focus Words in Lead',
    type: 'words',
  },
  wordsDensity: {
    enabled: true,
    minValue: 0.5,
    maxValue: 3,
    score: 1,
    rule: 'SEO Focus Words density between 0.5% and 3%',
    type: 'words',
  },
  seoTitle: {
    enabled: true,
    score: 1,
    scope: 'serp',
    limit: 1,
    rule: 'SEO Focus Words in SEO Title',
    type: 'words',
  },
  seoDescription: {
    enabled: true,
    score: 1,
    scope: 'serp',
    limit: 1,
    rule: 'SEO Focus Words in SEO Description',
    type: 'words',
  },
  publicationUrl: {
    enabled: false,
    score: 1,
    scope: 'serp',
    limit: 1,
    rule: 'SEO Focus Words in Article URL',
    type: 'words',
  },
  headline: {
    enabled: true,
    score: 1,
    rule: 'SEO Focus Words in Title',
    type: 'words',
  },
  topMediaCaption: {
    enabled: true,
    score: 1,
    rule: 'SEO Focus Words in Top Media Caption',
    type: 'words',
  },
  seoTitleLength: {
    enabled: true,
    minLength: 30,
    maxLength: 65,
    limitMin: 25,
    limitMax: 30,
    score: 1,
    rule: 'SEO Title length between 30 and 65 characters',
    type: 'general',
  },
  seoDescriptionLength: {
    enabled: true,
    maxLength: 165,
    minLength: 130,
    limitMin: 30,
    limitMax: 125,
    score: 1,
    rule: 'SEO Description length between 130 and 165 characters',
    type: 'general',
  },
  metadata: {
    enabled: false,
    minValue: 4,
    limitMin: 2,
    score: 1,
    rule: 'Keywords in the article',
    type: 'general',
  },
  internalLinks: {
    enabled: true,
    score: 1,
    rule: 'Links to the same site',
    type: 'general',
  },
  externalLinks: {
    enabled: true,
    score: 0.5,
    rule: 'Links to other web sites',
    type: 'general',
  },
  partnerLinks: {
    enabled: false,
    score: 0.5,
    rule: 'Links to partner sites',
    type: 'general',
  },
  embeds: {
    enabled: false,
    score: 1,
    rule: 'Content Embeds in the article',
    type: 'general',
  },
  paragraphsInArticle: {
    enabled: true,
    minNumb: 3,
    score: 1,
    rule: 'The article has at least 3 paragraphs',
    type: 'read',
  },
  wordsInParagraph: {
    enabled: true,
    maxNumber: 20,
    score: 1,
    rule: 'Each paragraph has at least 20 words',
    type: 'read',
  },
  paragraphsWithH2: {
    enabled: true,
    score: 1,
    minNumb: 3,
    rule: 'The article has at least 3 H2 titles',
    type: 'read',
  },
  sentenceLength: {
    enabled: true,
    score: 1,
    maxValue: 20,
    rule: 'The length of the sentences must not exceed 20 words',
    type: 'read',
  },
  consecutiveSentence: {
    enabled: true,
    score: 1,
    rule: 'Consecutive sentences check: three or more sentences in a row not all starting with the same word',
    type: 'read',
  },
}
