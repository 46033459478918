import { type FactLanguage } from './fact-checking'

export const factLanguage: FactLanguage[] = [
  { id: 1, code: 'all', text: 'All Languages' },
  { id: 2, code: 'ar', text: 'Arabic' },
  { id: 3, code: 'bn', text: 'Bengali' },
  { id: 4, code: 'bg', text: 'Bulgarian' },
  { id: 5, code: 'my', text: 'Burmese' },
  { id: 6, code: 'ca', text: 'Catalan' },
  { id: 7, code: 'zh', text: 'Chinese' },
  { id: 8, code: 'hr', text: 'Croatian' },
  { id: 9, code: 'cs', text: 'Czech' },
  { id: 10, code: 'da', text: 'Danish' },
  { id: 11, code: 'nl', text: 'Dutch' },
  { id: 12, code: 'en', text: 'English' },
  { id: 13, code: 'fil', text: 'Filipino' },
  { id: 14, code: 'fi', text: 'Finnish' },
  { id: 15, code: 'fr', text: 'French' },
  { id: 16, code: 'de', text: 'German' },
  { id: 17, code: 'el', text: 'Greek' },
  { id: 18, code: 'gu', text: 'Gujarati' },
  { id: 19, code: 'iw', text: 'Hebrew' },
  { id: 20, code: 'hi', text: 'Hindi' },
  { id: 21, code: 'hu', text: 'Hungarian' },
  { id: 22, code: 'id', text: 'Indonesian' },
  { id: 23, code: 'it', text: 'Italian' },
  { id: 24, code: 'ja', text: 'Japanese' },
  { id: 25, code: 'kn', text: 'Kannada' },
  { id: 26, code: 'kk', text: 'Kazakh' },
  { id: 27, code: 'ko', text: 'Korean' },
  { id: 28, code: 'ku', text: 'Kurdish' },
  { id: 29, code: 'mk', text: 'Macedonian' },
  { id: 30, code: 'ms', text: 'Malay' },
  { id: 31, code: 'ml', text: 'Malayalam' },
  { id: 32, code: 'mr', text: 'Marathi' },
  { id: 33, code: 'ne', text: 'Nepali' },
  { id: 34, code: 'no', text: 'Norwegian' },
  { id: 35, code: 'or', text: 'Oriya' },
  { id: 36, code: 'pa', text: 'Panjabi / Punjabi' },
  { id: 37, code: 'fa', text: 'Persian' },
  { id: 38, code: 'pl', text: 'Polish' },
  { id: 39, code: 'pt', text: 'Portuguese' },
  { id: 40, code: 'ro', text: 'Romanian' },
  { id: 41, code: 'ru', text: 'Russian' },
  { id: 42, code: 'sr', text: 'Serbian' },
  { id: 43, code: 'si', text: 'Sinhalese' },
  { id: 44, code: 'sk', text: 'Slovak' },
  { id: 45, code: 'es', text: 'Spanish' },
  { id: 46, code: 'sv', text: 'Swedish' },
  { id: 47, code: 'ta', text: 'Tamil' },
  { id: 48, code: 'te', text: 'Telugu' },
  { id: 49, code: 'th', text: 'Thai' },
  { id: 50, code: 'tr', text: 'Turkish' },
  { id: 51, code: 'uk', text: 'Ukrainian' },
  { id: 52, code: 'ur', text: 'Urdu' },
]
