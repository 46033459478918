<script lang="ts">
  import { type WritableStore, map } from 'nanostores'
  import { type Content } from 'common/src/types'

  export let content: Content
  export let panel: any
  $$props

  const EMPTY_ID = '-'
  let lastId = EMPTY_ID
  let storedValue: { [key: string]: any } = {}
  let panelValue: WritableStore<any> = map({})

  const updateStored = (value: any) => {
    const id = content?.id
    if (id === EMPTY_ID || id !== lastId) return
    storedValue[id] = value
  }

  const updateValues = (id: string) => {
    if (!id || [lastId, EMPTY_ID].includes(id)) {
      return
    }
    lastId = id
    panelValue.set(storedValue[id] || {})
  }

  $: updateValues(content?.id)
  $: updateStored($panelValue)
</script>

<svelte:component this={panel.component} bind:value={$panelValue} {$$props} {content} />
