import fastEqual from 'fast-deep-equal'
import { get } from 'svelte/store'

import { State } from '../../../../../../store/state'
import { filteredStatus } from '../../../../../../store/statuses'

import { FilterManager } from '../../../../../content/filter/filter-manager'
import { MultiListFilter } from '../../../../../content/filter/multi-list-filter'

export type LookupManagedKey = 'partition' | 'source' | 'status'

export const removeSearchPrefix = (type: string) => type.toLowerCase().replace(/^search/i, '')

export const statusFilter = (_state: State, filterManager: FilterManager) => {
  const kind = filterManager.contentKind.kind

  const statuses = Array.from(
    new Set(filteredStatus(removeSearchPrefix(kind?.type), _state).map((status) => status.label))
  )

  filterManager.status.setOptions(statuses)
  filterManager.status.buildItems()

  if (kind.isLeaf) {
    const publishedId = get(filterManager.status.items).find(
      (item) => item.text === _state.publishedStatus
    )?.id

    filterManager.status.selected.set([`${publishedId}`])
  }

  return { items: filterManager.status.items, selected: filterManager.status.selected }
}

export const statusUpdate = (_state: State, filterManager: FilterManager, _filter = statusFilter) => {
  const kind = filterManager.contentKind.kind
  const statuses = Array.from(
    new Set(filteredStatus(removeSearchPrefix(kind?.type), _state).map((status) => status.label))
  )
  const currentOptions = get(filterManager.status.items)?.map((option) => option.text)

  if (fastEqual(statuses, currentOptions)) {
    return { items: undefined, selected: undefined }
  }

  return _filter(_state, filterManager)
}

export const lookupsFilter = (_state: State, filterManager: FilterManager, type: LookupManagedKey) => {
  const lookups = _state.lookups.get()

  const key = `${type}s`
  const data = (lookups as any)[key]
  const filter = (filterManager as any)[type] as MultiListFilter<any>
  filter.setOptions(data)
  filter.buildItems()

  return { items: filter.items, selected: filter.selected }
}

export const lookupsUpdate = (
  _state: State,
  filterManager: FilterManager,
  type: LookupManagedKey,
  _filter = lookupsFilter
) => {
  const lookups = _state.lookups.get()

  const key = `${type}s`
  const data = (lookups as any)[key]
  const filter = (filterManager as any)[type] as MultiListFilter<any>
  const currentOptions = get(filter.items)?.map((option) => option.text)

  if (fastEqual(data, currentOptions)) {
    return { items: undefined, selected: undefined }
  }

  return _filter(_state, filterManager, type)
}

export const setupFilter = (_state: State, filterManager: FilterManager, type: LookupManagedKey) => {
  if (type === 'status') {
    return statusFilter(_state, filterManager)
  }

  return lookupsFilter(_state, filterManager, type)
}

export const updateFilter = (_state: State, filterManager: FilterManager, type: LookupManagedKey) => {
  if (type === 'status') {
    return statusUpdate(_state, filterManager)
  }

  return lookupsUpdate(_state, filterManager, type)
}
