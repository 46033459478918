<script lang="ts">
  import { getContext } from 'svelte'
  import { InlineLoading, Search } from 'carbon-components-svelte'
  import SearchIcon from 'carbon-icons-svelte/lib/Search.svelte'

  import MyButton from '../../../../atoms/my-button.svelte'
  import Dropdown from '../../../../atoms/filterable/carbon-dropdown.svelte'

  import DateRange from './date-range/date-range.svelte'
  import { FactCheckingService } from './fact-checking'
  import FactResultItem from './result-item/fact-result-item.svelte'
  import { RIGHT_PANEL_CONTEXT } from '../../right-panel'
  import StyleFieldsetLabel from '../../../../../style/style-fieldset-label.svelte'
  import { state } from '../../../../../store/state'
  import { factLanguage } from './fact-checking-lang'

  const factChecking = new FactCheckingService(state)
  const { selectedLanguage, inputValue, loading, searchResult } = factChecking

  let noResults = false

  const doSearch = () => {
    noResults = false

    factChecking.doSearch().then(() => {
      noResults = $searchResult.length === 0
    })
  }

  const { columns } = getContext(RIGHT_PANEL_CONTEXT) as any

  $$props
</script>

<div class="flex flex-col max-h-full h-full w-full gap-2" style="--gap: {$columns === 1 ? 300 : 220}px">
  <div class="px-4 flex flex-col gap-4">
    <div class="fact-search flex gap-2">
      <div class="w-full">
        <Search bind:value={$inputValue} disabled={$loading} icon={$loading ? InlineLoading : SearchIcon} />
      </div>
      {#if $columns !== 1}
        <div class="flex justify-end min-w-fit">
          <MyButton text="Search" disabled={$loading || !$inputValue} onClick={doSearch} />
        </div>
      {/if}
    </div>
    <div class="flex flex-row justify-start w-full">
      {#if $columns === 1}
        <div class="w-3/5 flex items-end">
          <div class="flex-grow">
            <StyleFieldsetLabel>
              <Dropdown
                bind:selectedId={$selectedLanguage}
                items={factLanguage}
                filterable={true}
                titleText="Language"
                disabled={$loading}
              />
            </StyleFieldsetLabel>
          </div>
        </div>
        <div class="flex justify-end w-full fact-search h-full items-end">
          <MyButton text="Search" disabled={$loading || !$inputValue} onClick={doSearch} />
        </div>
      {/if}
    </div>
  </div>
  <div class="flex items-end gap-2 px-4">
    <div class="w-full h-fit">
      <DateRange {loading} {factChecking} />
    </div>
    {#if $columns !== 1}
      <div class="w-2/6 flex items-center mb-[2px] h-full">
        <div class="flex-grow h-full flex justify-start">
          <StyleFieldsetLabel>
            <Dropdown
              bind:selectedId={$selectedLanguage}
              items={factLanguage}
              filterable={true}
              titleText="Language"
              disabled={$loading}
            />
          </StyleFieldsetLabel>
        </div>
      </div>
    {/if}
  </div>

  <div class="container px-4 flex flex-col gap-4">
    <div class="w-full h-fit flex flex-col border border-selected border-solid">
      {#each $searchResult as fact}
        <FactResultItem {fact} />
      {/each}
    </div>
    {#if noResults}
      <div class="w-full text-center font-bold py-4">
        <p>No results found</p>
        <p>Try adjusting your search criteria</p>
      </div>
    {/if}
  </div>
</div>

<style lang="postcss">
  .container {
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: calc(100% - var(--gap));
  }

  .fact-search :global(.my-button) {
    padding: 10px;
    width: 100;
    min-height: unset;
    max-height: 3rem;
  }
</style>
