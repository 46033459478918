<div class="button-selected-accent">
  <slot />
</div>

<style lang="postcss">
  .button-selected-accent :global(.my-button-selected) {
    background-color: var(--accent);
    color: var(--background);
  }

  .button-selected-accent :global(.my-button:hover) {
    color: unset;
  }

  .button-selected-accent :global(.my-button-selected:hover) {
    color: unset;
    color: var(--background);
    background-color: rgba(var(--accent-rgb), 0.6);
  }

  .button-selected-accent :global(.bx--btn--ghost:focus) {
    box-shadow: unset;
    border-color: var(--background);
  }
</style>
