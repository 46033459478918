import { Content, ContentId } from 'common/src/types'
import { DnDHints, type DnDHint, NullDnDHints } from '../../store/dnd-hints'
import { validateRelated } from './article/related-contents'
import { State } from '../../store/state'
import { GalleryKind, ImageKind } from '../../store/kind'

export const buildAction: (
  content: Content,
  prop: string,
  updateContent: (prop: keyof Content) => (value: any) => void,
  state: State
) => (content: ContentId) => void =
  (
    content: Content,
    prop: string,
    updateContent: (prop: keyof Content) => (value: any) => void,
    state: State
  ) =>
  async (contentId) => {
    if (!content) {
      return
    }

    const hints = state.dragAndDropHints.get()
    const targetHint = hints.hints.find((item: DnDHint) => item.name === prop)

    if (targetHint) {
      targetHint.selected = true
      hints.hints.forEach((item: DnDHint) => {
        if (item.name !== prop) {
          item.hidden = true
        }
      })

      state.dragAndDropHints.set(new DnDHints(content, hints.hints))

      setTimeout(() => {
        state.dragAndDropHints.set(new NullDnDHints())
      }, 400)
    }

    const propMapping = {
      topMedia: 'topMedia',
      teaser: 'teaserImage',
      search: 'socialImage',
      social: 'socialImage',
      relatedContents: 'relatedContents',
    }

    const update = updateContent((propMapping as any)[prop])

    if (prop === 'relatedContents') {
      const resContent = await validateRelated(contentId.id, content, state)
      if (resContent) {
        update(resContent.relatedContents)
      }
    }

    if (contentId.type === ImageKind.type || contentId.type === GalleryKind.type) {
      update(contentId.id)
    }
  }
