<script lang="ts">
  import { onMount } from 'svelte'
  import type { Content, WithText } from 'common/src/types'

  import { state } from '../../../../../store/state'
  import MyButton from '../../../../atoms/my-button.svelte'
  import ButtonSelectedAccent from '../../../../../style/button-selected-accent.svelte'
  import { AIList } from './ai-router-list'
  import AiPanelAtom from './ai-panel-atom.svelte'

  export let content: Content & WithText

  const router = state.aiRouter

  const { open, pinned, current, handleOpen } = router

  let mainElement: HTMLDivElement

  const handleClickOutside = (event: any) => {
    if (mainElement && !mainElement.contains(event.target) && !$pinned) {
      $open = false
    }
  }

  onMount(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  let hasScrolledToEnd = false
  let hasScrolledToBeginning = true
  let menu: any
  let menuWidth = 0
  const handleScroll = (e: any) => {
    if (e?.target === undefined) return
    const target = e.target
    const limit = 30
    hasScrolledToEnd = target.scrollLeft + target.clientWidth >= target.scrollWidth - limit
    hasScrolledToBeginning = target.scrollLeft <= limit
  }
  const scrollToBeginning = () => {
    if (menu === undefined) return
    menu.scrollLeft = 0
  }
  const scrollToEnd = () => {
    if (menu === undefined) return
    menu.scrollLeft = menu.scrollWidth - menu.clientWidth
  }

  const halfWay = AIList.length / 2

  $: $current >= halfWay ? scrollToEnd() : scrollToBeginning()
  $: menuWidth && handleScroll({ target: menu })

  const updateMenuOption = (content: Content) => {
    currentAIList = AIList.filter(
      (item) => !content || item.activeOn === '*' || item.activeOn.includes(content.type)
    )

    const currentPresent = currentAIList.some((item) => item.id === $current)

    if (!currentPresent) {
      $current = currentAIList[0].id
    }
  }

  let currentAIList = [...AIList]

  $: updateMenuOption(content)
</script>

<div
  class="relative ai-router flex flex-col gap-6 w-full overflow-x-hidden"
  bind:this={mainElement}
  role="dialog"
>
  <div class="sticky overflow-y-hidden mx-4 h-10 shrink-0 top-0 z-50 bg-[var(--background)]">
    <div class="menu overflow-x-auto" on:scroll={handleScroll} bind:this={menu} bind:clientWidth={menuWidth}>
      <div class="selectors flex flex-row w-fit">
        {#each currentAIList as panel}
          <ButtonSelectedAccent>
            <MyButton
              className="mx-1 text-s whitespace-nowrap rounded-[var(--round)]"
              isSelected={$current === panel.id}
              text={panel.name}
              kind="ghost"
              size="field"
              onClick={() => handleOpen(panel)}
            />
          </ButtonSelectedAccent>
        {/each}
      </div>
    </div>
    <button class="scroll beginning" class:hidden={hasScrolledToBeginning} on:click={scrollToBeginning} />
    <button class="scroll end" class:hidden={hasScrolledToEnd} on:click={scrollToEnd} />
  </div>
  {#each AIList as panel}
    <div class:hidden={$current !== panel.id}>
      <AiPanelAtom {panel} {content} />
    </div>
  {/each}
</div>

<style>
  .scroll {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    width: 2rem;
    height: 100%;
    cursor: pointer;
    background: linear-gradient(to right, transparent, var(--background));
  }
  .scroll:after {
    content: '▶';
    color: var(--primary);
  }
  .scroll.beginning {
    left: 0;
    transform-origin: 50% 50%;
    transform: rotate(180deg);
  }
  .scroll.end {
    right: 0;
  }
</style>
