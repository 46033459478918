<script lang="ts">
  import MyButton from './my-button.svelte'

  export let kind:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'ghost'
    | 'danger'
    | 'danger-tertiary'
    | 'danger-ghost'
    | undefined = 'primary'

  export let size: 'default' | 'field' | 'small' | 'lg' | 'xl' | undefined = 'default'
  export let isSelected: boolean | undefined = false
  export let text: string | undefined = ''
  export let icon: any = undefined
  export let tooltip: string | undefined = undefined
  export let tooltipDelay = 1000
  export let tooltipAlignment: 'start' | 'center' | 'end' = 'center'
  export let tooltipPosition: 'top' | 'right' | 'bottom' | 'left' = 'top'
  export let skeleton = false
  export let disabled = false
  export let onClick: () => void = () => {
    // do nothing
  }
  export let className = ''
  export let padding = ''
</script>

<div style="--padding: {padding};" class:padding>
  <MyButton
    {className}
    {kind}
    {size}
    {isSelected}
    {text}
    {icon}
    {tooltip}
    {tooltipDelay}
    {tooltipAlignment}
    {tooltipPosition}
    {skeleton}
    {disabled}
    {onClick}
  >
    <slot />
  </MyButton>
</div>

<style>
  .padding :global(.bx--btn) {
    padding: var(--padding);
  }
</style>
