<script lang="ts">
  import { onMount } from 'svelte'
  import { type WritableStore } from 'nanostores'

  import Label from '../../../../../../style/label.svelte'
  import { FactCheckingService, dateRangeBtnData } from '../fact-checking'
  import RangeButton from './range-button.svelte'
  import CustomDateRange from './custom-date-range.svelte'

  export let loading: WritableStore<boolean>
  export let factChecking: FactCheckingService

  const { customDateVisible } = factChecking

  let overflow = $customDateVisible ? 'overflow-visible' : 'overflow-hidden'

  const unsubscribe = customDateVisible.listen((visible) => {
    if (visible) {
      setTimeout(() => (overflow = 'overflow-visible'), 500)
    } else {
      overflow = 'overflow-hidden'
    }
  })

  onMount(() => {
    return () => {
      unsubscribe()
    }
  })
</script>

<div class="data-range flex flex-col gap-2 h-full">
  <div class="w-full flex flex-col">
    <Label>Date Range</Label>
    <div class="flex gap-2 h-full w-full">
      {#each dateRangeBtnData as data}
        <RangeButton disabled={$loading} {data} {factChecking} />
      {/each}
    </div>
  </div>
  <div
    class={`${$customDateVisible ? 'h-16' : 'h-0'} w-full transition-all duration-500 relative ${overflow}`}
  >
    <CustomDateRange {factChecking} />
  </div>
</div>
