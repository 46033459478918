import AIRouter from './pane-list/ai/ai-router.svelte'
import SearchPane from './pane-list/search-pane.svelte'
import Meta from '../../meta/meta.svelte'
import ContentView from '../../content-view/content-view.svelte'
import SEOTab from './pane-list/seo-tab/seo-tab.svelte'
import FactChecking from './pane-list/fact-checking/fact-checking.svelte'
import WebPreview from './pane-list/web-preview/web-preview.svelte'
import { ArticleKind } from '../../../store/kind'

export type PaneElement = {
  id: number
  name: string
  component: any
  activeOn: '*' | string[]
}

export const ASK_AI_TAB_ID = 0
export const ASK_AI_TAB_NAME = 'Ask AI'
export const SEO_TAB_NAME = 'SEO'
export const WEB_PREVIEW_TAB_NAME = 'Web Preview'

export const paneList: PaneElement[] = [
  {
    id: ASK_AI_TAB_ID,
    name: ASK_AI_TAB_NAME,
    component: AIRouter,
    activeOn: '*',
  },
  {
    id: 1,
    name: 'Search',
    component: SearchPane,
    activeOn: '*',
  },
  {
    id: 2,
    name: 'Meta',
    component: Meta,
    activeOn: '*',
  },
  {
    id: 3,
    name: 'Content view',
    component: ContentView,
    activeOn: '*',
  },
  {
    id: 4,
    name: WEB_PREVIEW_TAB_NAME,
    component: WebPreview,
    // TODO: add support for other kinds when the PMC-2806 is fixed up ([VideoKind.type, AudioKind.type, GalleryKind.type, LiveblogKind.type])
    activeOn: [ArticleKind.type],
  },
  {
    id: 5,
    name: 'Fact Checking',
    component: FactChecking,
    activeOn: '*',
  },
  {
    id: 6,
    name: SEO_TAB_NAME,
    component: SEOTab,
    activeOn: [ArticleKind.type],
  },
]
