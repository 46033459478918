<script lang="ts">
  import { getContext, onMount } from 'svelte'
  import { capitalize } from 'common/src/utils'

  import Dropdown from '../../../../../atoms/filterable/carbon-dropdown.svelte'
  import FilterStyle from '../../../../../../style/style-filter.svelte'

  import { state } from '../../../../../../store/state'

  import { RIGHT_PANEL_CONTEXT } from '../../../right-panel'

  import { SEARCH_PANE_CONTEXT } from '../../search-pane'

  import { filter, kindAsId, kindAsString } from './kind-filter'
  import { LiveblogKind } from '../../../../../../store/kind'

  const { selected, items } = filter(state)

  const { searchKind } = getContext(RIGHT_PANEL_CONTEXT) as any
  const { setKind } = getContext(SEARCH_PANE_CONTEXT) as any

  const searchUnsubscribe = searchKind.subscribe((kind: string) => {
    const kindId = kindAsId((kind || '').replace(/search/gi, '').toLowerCase())
    if (kindId !== -1) {
      if (kindId === $selected) return
      $selected = kindId
    }
  })

  $: setKind(`search${capitalize(kindAsString($selected))}`)
  // TODO:[UNIMPLEMENTED]:Filter out liveblogs from the dropdown items. Replace _items with $items and remove next line after liveblogs are added.
  $: _items = $items.filter((i) => i.text !== LiveblogKind.nameOnState)

  onMount(() => {
    return () => searchUnsubscribe()
  })
</script>

<FilterStyle>
  <div class="filter single-list rounded-[var(--round)]">
    <Dropdown bind:selectedId={$selected} bind:items={_items} filterable={false} floatingTitleText="Type" />
  </div>
</FilterStyle>
