<script lang="ts">
  import { onMount } from 'svelte'
  import { writable } from 'svelte/store'

  import { Slider } from 'carbon-components-svelte'
  import Add from 'carbon-icons-svelte/lib/Add.svelte'
  import WatsonHealthMagnify from 'carbon-icons-svelte/lib/WatsonHealthMagnify.svelte'
  import SidePanelOpen from 'carbon-icons-svelte/lib/SidePanelOpen.svelte'
  import Subtract from 'carbon-icons-svelte/lib/Subtract.svelte'

  import { state } from '../../../../store/state'
  import MyButton from '../../../atoms/my-button.svelte'

  const { settings } = state

  export let zoom = writable(1)
  export let documentWidth = writable(900)
  export let panelOpen = writable(false)

  $: {
    settings.upsert({ zoom: $zoom })
  }

  $: data = settings.data
  $: multiplier = $data.columnMultiplier

  const setWidth = (value: number) => {
    documentWidth.set(value)
    settings.upsert({ width: value })
  }

  const setColumns = (value: 1 | 1.5 | 2) => {
    settings.upsert({ columnMultiplier: value })
  }

  onMount(() => {
    settings.restore()
    zoom.set(settings.data.get().zoom)
    documentWidth.set(settings.data.get().width)
  })
</script>

<div
  class="zoom flex flex-row justify-center bg-[var(--background)] absolute bottom-4 right-8 z-20"
  class:zoom-collapsed={!$panelOpen}
  class:zoom-expanded={$panelOpen}
>
  <div class="controls flex flex-row items-center pl-4 pr-4 pt-1 pb-1" style="--cds-ui-05: var(--dark-gray);">
    <MyButton
      kind="ghost"
      icon={Subtract}
      tooltip="Zoom out by 10%"
      onClick={() => {
        zoom.set(Math.max(0.5, $zoom - 0.1))
      }}
    />
    <Slider hideTextInput bind:value={$zoom} min={0.5} max={2} step={0.1} />
    <MyButton
      kind="ghost"
      icon={Add}
      tooltip="Zoom in by 10%"
      onClick={() => {
        zoom.set(Math.min(2, $zoom + 0.1))
      }}
    />
    <div class="w-1" />
    <MyButton
      kind="ghost"
      text="Reset zoom"
      tooltip="Reset zoom"
      onClick={() => {
        zoom.set(1)
      }}
    />
    <div class="w-20" />
    <MyButton
      kind="ghost"
      text="Wide"
      tooltip="Wide editor"
      isSelected={$documentWidth === 1400}
      onClick={() => {
        setWidth(1400)
      }}
    />
    <MyButton
      kind="ghost"
      text="Medium"
      tooltip="Medium editor"
      isSelected={$documentWidth === 1100}
      onClick={() => {
        setWidth(1100)
      }}
    />
    <MyButton
      kind="ghost"
      text="Narrow"
      tooltip="Narrow editor"
      isSelected={$documentWidth === 900}
      onClick={() => {
        setWidth(900)
      }}
    />

    <div class="w-20" />
    <div class="flex flex-row">
      <div class="flex items-center w-16">Sidebar</div>
      <MyButton
        kind="ghost"
        tooltip="4 column layout"
        onClick={() => setColumns(2)}
        isSelected={multiplier === 2}
      >
        <svg fill="var(--dark-gray)" width="24px" height="24px" viewBox="0 0 35 35">
          <g>
            <g>
              <rect width="6.119" height="35" />
              <rect x="9.615" width="6.133" height="35" />
              <rect x="19.245" width="6.116" height="35" />
              <rect x="28.873" width="6.127" height="35" />
            </g>
          </g>
        </svg>
      </MyButton>
      <MyButton
        kind="ghost"
        tooltip="3 column layout"
        onClick={() => setColumns(1.5)}
        isSelected={multiplier === 1.5}
        ><svg fill="var(--dark-gray)" width="24px" height="24px" viewBox="0 0 35 35">
          <g>
            <g>
              <rect x="9.615" width="6.133" height="35" />
              <rect x="19.245" width="6.116" height="35" />
              <rect x="28.873" width="6.127" height="35" />
            </g>
          </g>
        </svg>
      </MyButton>
      <MyButton
        kind="ghost"
        tooltip="2 column layout"
        onClick={() => setColumns(1)}
        isSelected={multiplier === 1}
        ><svg fill="var(--dark-gray)" width="24px" height="24px" viewBox="0 0 35 35">
          <g>
            <g>
              <rect x="19.245" width="6.116" height="35" />
              <rect x="28.873" width="6.127" height="35" />
            </g>
          </g>
        </svg>
      </MyButton>
    </div>
  </div>
  <div class="absolute right-1 pt-1">
    {#if $panelOpen}
      <MyButton
        kind="tertiary"
        text="Close"
        tooltip="Close zoom panel"
        icon={SidePanelOpen}
        onClick={() => {
          panelOpen.set(false)
        }}
      />
    {:else}
      <MyButton
        className="open-button"
        kind="ghost"
        tooltip="Open zoom panel"
        tooltipAlignment="center"
        tooltipPosition="top"
        icon={WatsonHealthMagnify}
        onClick={() => {
          panelOpen.set(true)
        }}
      />
    {/if}
  </div>
</div>

<style>
  .zoom {
    min-width: calc(100vw - 6rem);
    border: 1px solid var(--selected);
    transition: width 0.3s ease;
  }
  .zoom :global(.bx--slider__range-label) {
    display: none;
  }
  .zoom :global(.bx--form-item) {
    flex-grow: 0;
  }
  .zoom-collapsed {
    max-width: 3.8rem;
    min-width: unset;
  }
  .zoom-collapsed .controls {
    visibility: hidden;
    overflow: hidden;
  }
  .zoom :global(.open-button .bx--tooltip) {
    transform: translate(-2.3rem, -3rem);
  }

  .zoom :global(.open-button .bx--tooltip__caret) {
    margin-right: 1.313rem;
  }
</style>
