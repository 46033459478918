export const domainsConfig = {
  INTERNAL: [
    {
      name: 'https://mytype.atexcloud.io/',
    },
  ],
  FAVORITES: [
    {
      name: 'https://mytype.atexcloud.io/',
    },
    {
      name: 'https://atexherald.atexcloud.io/',
    },
    {
      name: 'https://atexgazette.atexcloud.io/',
    },
  ],
}
