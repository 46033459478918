<script lang="ts">
  import { getContext, onMount } from 'svelte'

  import MultiSelect from '../../../../../atoms/filterable/carbon-multi-select.svelte'

  import { state } from '../../../../../../store/state'
  import FilterStyle from '../../../../../../style/style-filter.svelte'
  import { DEFAULT_FILTER_LABEL, filterLabels } from '../../../../../content/filter/filter-labels'
  import { SEARCH_PANE_CONTEXT } from '../../search-pane'
  import { type LookupManagedKey, updateFilter, setupFilter } from './lookup-filter'
  import { FilterManager } from '../../../../../content/filter/filter-manager'

  export let type: LookupManagedKey
  export let filterable = false
  export let floatingTitleText: string

  export let labelMessage = DEFAULT_FILTER_LABEL

  const { activeFilterManager } = getContext(SEARCH_PANE_CONTEXT) as any

  let filterManager: FilterManager
  let selected: any
  let items: any
  let currentKind: string

  const unsubscribe = state.lookups.listen(() => {
    if (!type) {
      return
    }

    const { items: _items, selected: _selected } = updateFilter(state, filterManager, type)
    if (_items && _selected) {
      items = _items
      selected = _selected

      currentKind = filterManager.contentKind.kind.nameOnState
    }
  })

  $: filterManager = $activeFilterManager
  $: if (filterManager && filterManager.contentKind?.kind?.nameOnState !== currentKind) {
    const { items: _items, selected: _selected } = setupFilter(state, filterManager, type)

    items = _items
    selected = _selected

    currentKind = filterManager.contentKind.kind.nameOnState
  }
  $: labelMessage = filterLabels($selected, filterManager[type])

  onMount(() => {
    return () => unsubscribe()
  })
</script>

<FilterStyle>
  {#if items && selected}
    <div class="filter filter-control single-list rounded-[var(--round)]">
      {#key currentKind}
        <MultiSelect
          type="default"
          bind:selectedIds={$selected}
          bind:items={$items}
          label={labelMessage}
          {filterable}
          {floatingTitleText}
        />
      {/key}
    </div>
  {/if}
</FilterStyle>

<style lang="postcss">
  .filter-control :global(.bx--multi-select__wrapper) {
    position: relative;
  }

  .filter-control :global(.bx--multi-select .bx--tag) {
    display: none;
  }

  .filter-control :global(.bx--multi-select__wrapper) {
    z-index: 10;
  }
</style>
