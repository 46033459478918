<script lang="ts">
  import { onMount } from 'svelte'
  import { totalScore } from '../pane-list/seo-tab/seo-tab'

  export let name: string
  export let enabled: boolean = false
  export let selected: boolean = false
  export let customClasses: string = ''

  let totalScoreValue: number
  let totalScoreColor: string = ''

  const doTheMath = (value = totalScoreValue) => {
    const color = value < 40 ? 'red' : value > 70 ? 'green' : 'orange'

    totalScoreColor = value < 40 ? `rgb(var(--${color}))` : value > 70 ? `var(--${color})` : `var(--${color})`

    const background = enabled
      ? value < 40
        ? `bg-[rgb(var(--light-${color}))]`
        : `bg-[var(--light-${color})]`
      : ''

    customClasses = background + (selected && enabled ? ` outline outline-1 outline-${color}-500` : '')
  }

  const unsubscribe = totalScore.subscribe((value) => {
    totalScoreValue = value
    doTheMath(value)
  })

  $: {
    if (selected || enabled) {
      doTheMath()
    } else {
      doTheMath()
    }
  }

  onMount(() => {
    return () => unsubscribe()
  })
</script>

<div class="title min-w-full min-h-full text-s">
  {name}
  {#if enabled}
    <span class="text-s" style={`color: ${totalScoreColor}; font-weight:bold; `}>: {totalScoreValue}% </span>
  {/if}
</div>
