<script lang="ts">
  import { getContext } from 'svelte'

  import DateRangeFilter from '../../../../../content/filter/date-range-filter.svelte'

  import FilterStyle from '../../../../../../style/style-filter.svelte'
  import { SEARCH_PANE_CONTEXT } from '../../search-pane'

  const { activeFilterManager } = getContext(SEARCH_PANE_CONTEXT) as any

  let currentKind: string | undefined

  $: filterManager = $activeFilterManager
  $: date = filterManager.date
  $: if (filterManager && filterManager.contentKind?.kind?.nameOnState !== currentKind) {
    currentKind = filterManager.contentKind.kind.nameOnState
  }
</script>

<FilterStyle>
  <div class="filter-control single-list rounded-[var(--round)]">
    {#key currentKind}
      <DateRangeFilter filter={date} floatingTitleText="Date" filterable={false} />
    {/key}
  </div>
</FilterStyle>
