import { shortId } from 'common/src/content'
import { type Content } from 'common/src/types'
import { state } from '../../../../../store/state'

export type WebPreviewClientCache = Record<string, WebPreviewObject>

export const cleanCacheIds = (version: string, cache: WebPreviewClientCache, clean = false) => {
  const id = shortId(version)
  for (const key in cache) {
    if (key.startsWith(id) && (key !== version || clean)) {
      delete cache[key]
    }
  }
}

export const createPreviewContentHash = (content: Content) => {
  if (!content) return ''
  const previewBundle = state.kindFromType(content.type).webPreviewFields || []
  previewBundle.sort()
  const string = previewBundle.reduce((acc: string, field) => {
    const add = `${field as string}:${content[field as keyof Content]}|`
    return acc + add
  }, '')
  const safe = encodeURIComponent(string)
  const encoded = window.btoa(safe)
  return encoded
}

export const getWebPreview = async (
  content: Content,
  cache: WebPreviewClientCache,
  cleanCache = false,
  getter: (content: Content) => Promise<string>
) => {
  const { version } = content
  cleanCacheIds(version, cache, cleanCache)

  if (!cache[version]) {
    cache[version] = new WebPreviewObject(await getter(content))
  }

  return cache[version]
}

export class WebPreviewObject {
  url: string

  constructor(url: string) {
    this.url = url
  }
}
