<script lang="ts">
  import { getContext } from 'svelte'
  import { writable } from 'svelte/store'

  import { debounce } from 'common/src/utils'

  import { InlineLoading, Search, Popover } from 'carbon-components-svelte'
  import Information from 'carbon-icons-svelte/lib/Information.svelte'
  import InformationFilled from 'carbon-icons-svelte/lib/InformationFilled.svelte'
  import SearchIcon from 'carbon-icons-svelte/lib/Search.svelte'

  import { state } from '../../../../../../store/state'

  import { filterContentKind } from '../../../../../../business-logic/director/director'
  import SearchInfo from '../../../../../search/search-info.svelte'
  import ArchiveSearchToggle from '../../../../../search/archive-search/archive-search-toggle.svelte'
  import { whenFullyLoaded } from '../../../../../atoms/my-is-mounted'

  import { SEARCH_PANE_CONTEXT } from '../../search-pane'

  export let popoverRef: any = null
  export let ftsStatus = writable('finished')

  const { activeContentKind, activeFilterManager } = getContext(SEARCH_PANE_CONTEXT) as any
  const { archiveSearchEnabled: showArchiveSearch } = state

  let text: string
  let popover = false
  let firstEmptyFilter = true

  const debouncedSearch = debounce(async (value: string) => {
    if (firstEmptyFilter) {
      firstEmptyFilter = false
      return
    }
    ftsStatus.set('active')
    const filter = { ...contentKind.filter.get() }
    filter.fts = value

    whenFullyLoaded(state, async () => {
      await filterContentKind(contentKind, filter)
      ftsStatus.set('finished')
    })
  }, 500)

  $: contentKind = $activeContentKind
  $: filterManager = $activeFilterManager
  $: archiveSearchEnabled = contentKind.archiveSearchEnabled
  $: {
    if (!$archiveSearchEnabled) {
      debouncedSearch(text)
    }
  }
</script>

<div class="relative">
  {#if $showArchiveSearch}
    <div class="absolute bottom-[4.2rem] right-[5.2rem]">
      <ArchiveSearchToggle {contentKind} {filterManager}></ArchiveSearchToggle>
    </div>
  {/if}
  <div bind:this={popoverRef} class="relative search flex-1">
    <div class="flex flex-row items-center">
      <div class="fts-search-input">
        <Search
          bind:value={text}
          icon={$ftsStatus === 'active' ? InlineLoading : SearchIcon}
          on:keyup={(ev) => {
            if (ev.key === 'Enter' && $archiveSearchEnabled) {
              debouncedSearch(text)
            }
          }}
        />
        <button
          class={`absolute top-1/4 mt-1 ${
            text ? 'right-12 mr-2' : 'right-0 mr-4'
          } flex items-center opacity-50 hover:opacity-100 transition-all duration-300`}
          class:opacity-100={popover}
          on:click={() => (popover = !popover)}
        >
          <div class:hidden={!popover}>
            <InformationFilled />
          </div>
          <div class:hidden={popover}>
            <Information />
          </div>
        </button>
      </div>
    </div>
    <Popover
      bind:open={popover}
      align="bottom-right"
      on:click:outside={({ detail }) => {
        if (!popoverRef) {
          return
        }
        if (popover) {
          popover = popoverRef.contains(detail.target)
        }
      }}
    >
      <SearchInfo />
    </Popover>
  </div>
</div>

<style lang="postcss">
  .search :global(.bx--popover) {
    overflow-y: unset;
    max-height: 20rem;
  }
  .search :global(.bx--search-input) {
    height: 2.75rem;
  }
</style>
