<script lang="ts">
  import { fade } from 'svelte/transition'

  import { type ContentId } from 'common/src/types'
  import { debounce } from 'common/src/utils'

  import CopyFile from 'carbon-icons-svelte/lib/CopyFile.svelte'

  import { State } from '../../store/state'
  import MyButtonWithPadding from './my-button-with-padding.svelte'
  import MyIcon from './my-icon.svelte'

  export let state: State
  export let relatedContent: ContentId | undefined
  export let tooltipPosition: 'top' | 'right' | 'bottom' | 'left' = 'top'
  export let inhibitCopyEffect = false
  export let showingText = 'Copied!'
  export let padding = ''

  let showing = false
  let effect = debounce(() => {
    showing = true
    setTimeout(() => {
      showing = false
    }, 600)
  }, 200)
</script>

<div class="flex flex-row items-center gap-2">
  <MyButtonWithPadding
    kind="ghost"
    size="small"
    tooltip="Copy"
    {padding}
    {tooltipPosition}
    onClick={() => {
      if (!relatedContent) {
        return
      }

      state.clipboard.setItem(relatedContent)
      if (!inhibitCopyEffect) {
        effect()
      }
    }}><MyIcon icon={CopyFile} /></MyButtonWithPadding
  >
  {#if showing}
    <span transition:fade class="text-s"> {showingText} </span>
  {/if}
</div>
