<script lang="ts">
  import { getContext } from 'svelte'

  import { capitalize } from 'common/src/utils'

  import { RIGHT_PANEL_CONTEXT } from '../../../right-panel'
  import { type FactResultItem } from '../fact-checking'

  export let fact: FactResultItem

  const { columns } = getContext(RIGHT_PANEL_CONTEXT) as any
</script>

<a href={fact.url} target="_blank" class="result-item">
  <div class={`pr-4 flex ${$columns === 1 ? 'items-start pt-2' : 'items-center'}`} style="height: inherit;">
    <img width="32" src="https://icons.duckduckgo.com/ip3/www.{fact.domain}.ico" alt="favicon afp.com" />
  </div>
  <div class="w-full h-full flex flex-col justify-between gap-4">
    <p class="font-semibold">{fact.title}</p>
    <div class={`flex justify-between ${$columns === 1 ? 'flex-col' : ''}`}>
      <div class={`flex ${$columns === 1 ? 'flex-col gap-1' : 'items-center gap-2'}`}>
        <p class="text-carbonGray">Verdict by {fact.domain}:</p>
        <p class="text-primary font-bold">{capitalize(fact.label)}</p>
      </div>
      <div class={`${$columns === 1 ? 'w-full justify-end -mt-6' : ''} flex`}>
        <p>{fact.publishDate.replaceAll('-', '.')}</p>
      </div>
    </div>
  </div>
</a>

<style lang="postcss">
  .result-item {
    background-color: var(--cds-layer);
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    min-height: 70px;
    border: solid 1px var(--selected);
    color: inherit;
  }

  .header:hover {
    background-color: #ffffff;
  }
</style>
