<script lang="ts">
  import { getContext } from 'svelte'
  import { convertDateToString } from 'common/src/utils'
  import { RIGHT_PANEL_CONTEXT } from '../../../right-panel'
  import { FactCheckingService, FactDateRange } from '../fact-checking'
  import MyDatePicker from '../../../../../atoms/date-time-picker/my-date-picker.svelte'

  export let factChecking: FactCheckingService
  export const { columns } = getContext(RIGHT_PANEL_CONTEXT) as any

  let from: Date | null = null
  let to: Date | null = null

  const handleDate = (type: 'from' | 'to') => (value: any) => {
    const date = value.detail.selectedDates[0]

    if (type == 'from') {
      from = date
      return
    }

    to = date
  }

  $: {
    if (from && to) {
      factChecking.setDateRange(FactDateRange.CUSTOM, {
        from: convertDateToString(from),
        to: convertDateToString(to),
      })
    }
  }
</script>

<div class={`custom-range flex flex-col absolute`} class:gap-2={true}>
  <div class="flex gap-2">
    <div class:from={$columns === 1}>
      <MyDatePicker label="from" maxDate={to} onChange={handleDate('from')} />
    </div>
    <div class:to={$columns === 1}>
      <MyDatePicker label="to" minDate={from} onChange={handleDate('to')} />
    </div>
  </div>
</div>

<style lang="postcss">
  .custom-range :global(.flatpickr-calendar) {
    position: absolute;
    top: 2.68rem;
  }

  @media (max-height: 804px) {
    .custom-range :global(.flatpickr-calendar) {
      position: absolute;
      top: -7rem;
    }
  }
</style>
