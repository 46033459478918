<script lang="ts">
  import { getContext } from 'svelte'
  import type { Content } from 'common/src/types'
  import { isDraft } from 'common/src/content'
  import {
    type WebPreviewClientCache,
    WebPreviewObject,
    getWebPreview,
    createPreviewContentHash
  } from './web-preview'
  import { state } from '../../../../../store/state'
  import MyButton from '../../../../atoms/my-button.svelte'
  import MyIcon from '../../../../atoms/my-icon.svelte'
  import { InlineLoading } from 'carbon-components-svelte'
  import ReloadIcon from 'carbon-icons-svelte/lib/Renew.svelte'
  import OpenIcon from 'carbon-icons-svelte/lib/Launch.svelte'
  import { EDITOR_SHELL_CONTEXT, type EditorShellContext } from '../../../editor-shell'
  import { WEB_PREVIEW_TAB_NAME, paneList } from '../../pane-list'

  export let content: Content

  let previewObject: WebPreviewObject | null = null
  let clientCache: WebPreviewClientCache = {}
  let lastVersion = '-'
  let previewContentHash = ''

  let isError = false
  let drafting = false

  const isPanelOpen = state.rightPanel.open
  const currentPanelId = state.rightPanel.current

  const { getWebPreviewUrl } = getContext<EditorShellContext>(EDITOR_SHELL_CONTEXT)

  const preview = async (reload = false) => {
    isError = false
    if (reload) {
      previewObject = null
    }
    let newObj
    try {
      newObj = await getWebPreview(content, clientCache, reload, getWebPreviewUrl)
    } catch (_e) {
      isError = true
    }
    if (newObj && newObj.url !== previewObject?.url) {
      previewObject = newObj
    }
  }

  const supportPreview = (content: Content) => Boolean(state.kindFromType(content?.type)?.isLeaf)

  const triggerPreview = async (newVersion: string, isPanelOpen: boolean, currentPanelId: number) => {
    if (!supportPreview(content)) {
      return
    }

    try {
      drafting = Boolean(content) && isDraft(content)
      const version = newVersion || '-'
      const isVersionDifferent = version !== lastVersion
      if (isVersionDifferent) {
        lastVersion = version
        previewObject = clientCache[version] || null
      }
      const notMe = currentPanelId !== paneList.find((pane) => pane.name === WEB_PREVIEW_TAB_NAME)?.id
      if (!isPanelOpen || notMe || drafting) {
        return
      }
      let reload = false
      const newPreviewContentHash = createPreviewContentHash(content)
      if (newPreviewContentHash !== previewContentHash) {
        previewContentHash = newPreviewContentHash
        reload = true
      }
      preview(reload)
    } catch (_e) {
      isError = true
    }
  }

  $: triggerPreview(content?.version, $isPanelOpen, $currentPanelId)

  $$props
</script>

<div class="max-h-full h-full w-full">
  {#if previewObject}
    <div class="absolute top-3 left-[8rem]">
      <MyButton
        kind="ghost"
        size="small"
        tooltip="Reload mobile preview"
        tooltipPosition="bottom"
        onClick={() => preview(true)}
      >
        <MyIcon icon={ReloadIcon} />
      </MyButton>
      <MyButton
        kind="ghost"
        size="small"
        tooltip="Open in new tab"
        tooltipPosition="bottom"
        onClick={() => window.open(previewObject?.url, '_blank')}
      >
        <MyIcon icon={OpenIcon} />
      </MyButton>
    </div>
  {:else if drafting}
    <div class="p-4">
      <p class="text-s text-gray-500">Preview is not available until you save draft at least once.</p>
    </div>
  {:else}
    <div class="p-4">
      {#if supportPreview(content)}
        <InlineLoading
          status={isError ? 'error' : 'active'}
          description={isError ? 'Error generating preview' : 'Generating preview...'}
        />
      {:else}
        <p class="text-s text-gray-500">Current content does not support web preview</p>
      {/if}
    </div>
  {/if}
  {#each Object.keys(clientCache) as version}
    {@const cache = clientCache[version]}
    <iframe
      title="web-preview"
      src={cache.url}
      class="block w-full h-full"
      class:hidden={cache.url !== previewObject?.url}
    />
  {/each}
</div>
